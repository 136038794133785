
.signin-container {
  .container-xxl {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f9fa;
    position: relative; // Add relative positioning for background images

    // Top-left background image
    &::before {
      content: '';
      position: absolute;
      top: -100px;
      left: -100px;
      width: 21rem; // Adjusted size
      height: 21rem; // Adjusted size
      background: url('../assets/image/auth-top-left.png') no-repeat;
      background-size: contain;
    }

    // Right-bottom background image
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 7rem; // Adjusted size
      height: 14.125rem; // Adjusted size
      background: url('../assets/image/auth-right-bottom.png') no-repeat;
      background-size: contain;
    }

    .authentication-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      
      .authentication-inner {
        width: 100%;
        max-width: 400px;

        .card {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          
          .card-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 2rem;

            .app-brand {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 1.5rem;

              .app-brand-text {
                display: flex;
                justify-content: center;
                align-items: center;

                .ndm-logo {
                  max-width: 100px;
                }
              }
            }

            h4 {
              text-align: center;
              margin-bottom: 1rem;
            }

            p {
              text-align: center;
              margin-bottom: 2rem;
            }

            .custom-form {
              width: 100%;

              .form-group {
                margin-bottom: 1.5rem;

                label {
                  display: block;
                  margin-bottom: 0.5rem;
                }

                input {
                  width: 100%;
                  padding: 0.5rem;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                }
              }

              .btn {
                width: 100%;
                padding: 0.75rem;
                border-radius: 4px;
                background-color: #007bff;
                color: #fff;
                border: none;
                cursor: pointer;

                &:disabled {
                  background-color: #6c757d;
                }
              }
            }

            .divider {
              width: 100%;
              display: flex;
              align-items: center;
              text-align: center;
              margin: 2rem 0;

              &::before,
              &::after {
                content: '';
                flex: 1;
                border-bottom: 1px solid #ddd;
              }

              &::before {
                margin-right: 0.5rem;
              }

              &::after {
                margin-left: 0.5rem;
              }

              .divider-text {
                padding: 0 0.5rem;
                color: #aaa;
              }
            }

            .d-flex {
              width: 100%;
              display: flex;
              justify-content: center;

              .btn-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 25%;
                background-color: #f8f9fa;
                border: 1px solid #ddd;
                margin-right: 0.5rem;
                transition: background-color 0.3s, color 0.3s;

                .btn-label-facebook .me-3 {
                  color: #007bff !important;
                }
                .btn-icon {
                  color: #dc3545 !important;
                }
                .btn-label-twitter {
                  color: #17a2b8;
                }

                &:last-child {
                  margin-right: 0;
                }

                &:hover {
                  background-color: #007bff;
                  color: #fff;
                }

                i {
                  font-size: 1.25rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

