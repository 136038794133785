

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .ndm-logo{ width: 100px; } */
/* .authentication-wrapper.authentication-basic .authentication-inner .card{    box-shadow: rgb(13 110 253 / 25%) 0px 10px 20px, rgb(13 110 253 / 25%) 0px 6px 6px; border-radius: 20px;} */


thead, tbody, tfoot, tr, td, th{text-align: -webkit-center;}
.menu-inner-shadow .flex-column .w-100 .navbar-nav{margin-top: 20px!important;}

/* .badge .bg-primary{
 background-color:rgba(90, 141, 238, 1) !important;
bg-danger { --bs-bg-opacity:1; background-color:rgba(255, 91, 92, 1) !important }
 
.bg-secondary { --bs-bg-opacity:1; background-color:rgba(105, 128, 154) !important }
.bg-success { --bs-bg-opacity:1; background-color:rgba(57, 218, 138) !important }    
.bg-warning { --bs-bg-opacity:1; background-color:rgba(253, 172, 65) !important }
.bg-info { --bs-bg-opacity:1; background-color:#00CFDD !important }
.bg-dark { --bs-bg-opacity:1; background-color:#495563 !important }

}

.bg-label-danger { background-color:#ffe5e5 !important; color:#ff5b5c !important}
.bg-label-primary { background-color:#e5edfc !important; color:#5a8dee !important}
.bg-label-success { background-color:#dff9ec !important; color:#39da8a !important}
.bg-label-secondary { background-color:#e7ebef !important; color:#69809a !important}
.bg-label-warning { background-color:#fff2e1!important; color:#fdac41 !important}
.bg-label-info { background-color:#d6f7fa !important; color: #00cfdd !important}
.bg-label-dark { background-color:#e2e4e6 !important; color: #495563!important} */

.bg-danger {
  --bs-bg-opacity: 1!important;
  /* background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; */
  background-color: #39da8a !important;
  /* text-transform: uppercase!important; */
}


.badge {
  display: inline-block;
  padding: 0.444em 0.75em!important; /* Padding values directly set */
  font-size: 0.813em!important; /* Font size directly set */
  font-weight: 500!important; 
  line-height: 1!important;
  text-transform: uppercase!important;
  color: #fff!important; /* Text color directly set */
  text-align: center;
  white-space: nowrap!important;
  vertical-align: baseline!important;
  /* vertical-align: baseline; */
  border-radius: 0.1875rem!important; /* Border radius directly set */
  border: 0px!important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

*, *::before, *::after {
  box-sizing: border-box;
}



